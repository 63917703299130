import React from "react"
import Layout from "../components/shared/layout/layout"
import SEO from "../components/shared/ui-elements/seo"
import WhereToBuyContent from "../components/where-to-buy/where-to-buy"
import {useIntl} from "gatsby-plugin-intl";

const WhereToBuy = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO title={intl.formatMessage({id: `seo.where-to-buy.title`})}
                 description={intl.formatMessage({id: `seo.where-to-buy.desc`})}/>
            <WhereToBuyContent/>
        </Layout>
    );
}

export default WhereToBuy
