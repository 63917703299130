import React from "react"
import "./where-to-buy.scss"
import Breadcrumbs from "../../components/shared/breadcrumbs/breadcrumbs"
import { useIntl } from "gatsby-plugin-intl"

const WhereToBuyContent = () => {
  const intl = useIntl();
  return (
    <div className="where-to-buy">
      <Breadcrumbs data={[{ name: intl.formatMessage({ id: `header.whereToBuy` }) }]}/>
      <div className="container where-to-buy-content">
        <div className="where-to-buy-column">
          <img src="https://luxdecor.ua/img/2-01.jpg"/>
          <p className="where-to-buy-shop-name">
            {intl.formatMessage({ id: `whereToBuy.item5` })}
          </p>
        </div>
        <a
            className="where-to-buy-column"
            href="https://www.budbox.com.ua/"
            target="_blank">
          <img src="https://luxdecor.ua/img/1-03.jpg"/>
          <p className="where-to-buy-shop-name">
            {intl.formatMessage({ id: `whereToBuy.item3` })}
          </p>
        </a>
        <div className="where-to-buy-column">
          <img src="https://luxdecor.ua/img/1-01.jpg"/>
          <p className="where-to-buy-shop-name">
            {intl.formatMessage({ id: `whereToBuy.item2` })}
          </p>
        </div>
        <div className="where-to-buy-column">
          <img src="https://luxdecor.ua/img/1-02.jpg"/>
          <p className="where-to-buy-shop-name">
            {intl.formatMessage({ id: `whereToBuy.item4` })}
          </p>
        </div>
        <div className="where-to-buy-column">
          <img src="https://luxdecor.ua/img/1-04.jpg"/>
          <p className="where-to-buy-shop-name">
            {intl.formatMessage({ id: `whereToBuy.item1` })}
          </p>
        </div>
        <div className="where-to-buy-column"></div>

      </div>
    </div>
  )
}

export default WhereToBuyContent
